import React, {Component} from 'react';
import {
  Switch,
  Route
} from "react-router-dom";

import Home from './Home';
import About from './About';
import Games from './Games';
import Toys from './Toys';
import RAndD from './RAndD';
import Animations from './Animations';
import Resume from './Resume';

class Content extends Component{
  render(){
    return(
      <div className="content">
        <Switch>
          Content goes here
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/games" component={Games}/>
          <Route path="/toys" component={Toys}/>
          <Route path="/rAndD" component={RAndD}/>
          <Route path="/animations" component={Animations}/>
          <Route path="/resume" component={Resume}/>
        </Switch>
      </div>
    );
  }
}

export default Content;
