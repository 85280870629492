import React, {Component} from 'react';
import AnimationEntry from './AnimationEntry';

class Animations extends Component{
  render(){
    return(
      <div>
      <h1>Animations</h1>
        <AnimationEntry
        title="Veggie Delight"
        tagline="A boy tries to entice a young beauty into a lunch date with the prospect of burgers. Too bad she's a vegetarian."
        text="This animation is an improvized comedy that was designed to be viewed on mobile devices. The entire short was made in one day from concept to final edit. This was made possible through the use of a prototype real-time animation program I created called Schnickschnack. The goal of this animation was to test the feasability of real-time animations as mobile content. While the prototype version of Schnickschnack did not allow the animator to create very articulate characters, the content of the animation overcame its lack of articulation for most viewers."
        video={<iframe width="560" height="315" src="https://www.youtube.com/embed/LA9Sf9_XAqQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
        />
        <AnimationEntry
          title="Don on the Farm"
          tagline="A duck seeks to become a rooster with the help of his barnyard friends."
          text="This 6 and a half minute short was created for Once Upon a Toon, a pilot for a children's show. This animation was made using ToonBoom Studio and was adapted from an original story by Joe Wos."
          video={<iframe width="560" height="315" src="https://www.youtube.com/embed/w3RoJnToltg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
        />
      </div>
    );
  }
}

export default Animations;
