import React from 'react';

const AnimationEntry = function (props){
  return(
    <div>
      <h3>{props.title}</h3>
      <p>{props.tagline}</p>
      <p>{props.text}</p>
      {props.video}
    </div>
  );
}


export default AnimationEntry;
