import React, {Component} from 'react';
// import {Route, NavLink, HashRouter} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import './styles/header.css';
// import './styles/font-awesome-4.7.0/css/font.awesome.min.css';


class Header extends Component{
  render(){
    return(
        <nav>
          <NavLink to="/"><div id="logo">Charles Brandt</div></NavLink>
          <ul class = "socialLinks">
            <li><a target ="_blank" href="https://github.com/luftbuefel"><img src="images/github.png" alt="link to github"/></a></li>
            <li><a target ="_blank" href="https://www.instagram.com/makingitwithcharles/"><img src="images/instagram.png" alt="link to instagram"/></a></li>
            <li><a target ="_blank" href="https://makingitwithcharles.tumblr.com"><img src="images/tumblr.png" alt = "link to tumblr"/></a></li>
            <a href="./documents/cBrandtResume.pdf">.pdf resume</a>
          </ul>
          <ul>
            <li><NavLink activeClassName="selected" exact to="/about">About</NavLink></li>
            <li><NavLink activeClassName="selected" to="/games">Games</NavLink></li>
            <li><NavLink activeClassName="selected" to="/toys">Toys</NavLink></li>
            <li><NavLink activeClassName="selected" to="/rAndD">Research and Development</NavLink></li>
            <li><NavLink activeClassName="selected" to="/animations">Animations</NavLink></li>
            <li><a href="https://makingitwithcharles.tumblr.com" target="_blank">Blog</a></li>
            <li><NavLink activeClassName="selected"  to="/resume">RÉSUMÉ</NavLink></li>
          </ul>
          {/*}<NavLink href="documents/cBrandtResume.pdf">Download Résumé</NavLink>*/}
        </nav>
    );
  }
}

export default Header;
