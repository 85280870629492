import React, {Component} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Header from './Header';
import Content from './Content';

class Main extends Component{
  render(){
    return(
      <Router>
        <Header/>
        <Content/>
      </Router>
    );
  }
}

export default Main;
