import React from 'react';

const Project = function(props){
  return(
    <div class="project">
      <a href={props.link}>
        <h2>{props.title}</h2>
        <img src={props.image}/>
        <p>{props.description}</p>
      </a>
    </div>
  );
}
export default Project;
