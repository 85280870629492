import React, {Component} from 'react';
import Project from './Project';

class Toys extends Component{
  render(){
    return(
      <div>
        <h1>Toys</h1>
        <div className="twoColumn">
          <Project link="https://www.openprocessing.org/sketch/804219" title="Spiral Art" image="./images/belaSpiral.jpg" description="Images are created by drawing a spiral. "/>
          <Project link="https://www.openprocessing.org/sketch/750260" title="Fireworks" image="./images/fireworks.jpg" description="Click to launch a firework. Press the space bar to start a show."/>
          <Project link="https://www.openprocessing.org/sketch/764750" title="Talking Head" image="./images/talkingHead.png" description="Speak into a microphone to make your new friend talk."/>
          <Project link="https://www.openprocessing.org/sketch/760858" title="I am a hologram!" image="./images/hologram.png" description="Follow onscreen instructions to become a hologram."/>
          <Project link="https://www.openprocessing.org/sketch/761194" title="I am halftoned!" image="./images/halftone.jpg" description="Follow onscreen instructions to look like a newspaper photograph."/>
          <Project link="https://www.openprocessing.org/sketch/816058" title="Menger Sponge" image="./images/mengerSponge.jpg" description="Use your mouse to interact with a 3D rotating Menger Sponge."/>
        </div>
      </div>
    );
  }
}

export default Toys;
