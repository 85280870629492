import React from 'react';

const GameEntry = function (props){
  return(
    <div className="gameEntry">
      <h3>{props.title}</h3>
      <p>{props.children}</p>
    </div>
  );
}

export default GameEntry;
