import React from 'react';

const About = function(props){
  return(
    <div>
      <h1>About</h1>
      <img src="./images/halftone.jpg" alt ="image of Charles Brandt"/>
      <p>
        Charles Brandt is a technologist, software developer, and artist. He received a Master of Entertainment Technology from Carnegie Mellon University in 2006. Throughought his career he has worked on a variety of projects including eLearning, social media games, online gaming/slots, and interactive AR/VR experiences.
      </p>
    </div>
  );
}

export default About;
