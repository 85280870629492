import React, {Component} from 'react';
import Project from './Project';

class Home extends Component{
  render(){
    return(
      <div>
        Charles Brandt is a software developer and technologist interested in pushing the limits of interactive media. He believes that by combining art with technology great new forms of creative expression and entertainment are possible. This site is devoted to his experiments and research. <br/>Visit his new blog to see his latest projects in making: <a href="https://makingitwithcharles.tumblr.com">https://makingitwithcharles.tumblr.com</a><br/>Visit the old blog here: <a href="http://animateinexile.blogspot.com/">http://animateinexile.blogspot.com</a>
         <div className="twoColumn">
          <Project link="https://www.openprocessing.org/sketch/707279" title="Blob.io" image="./images/blobio.png" description="In this game you consume blobs smaller than you to grow larger while avoid larger ones."/>
          <Project link="https://www.openprocessing.org/sketch/816058" title="Menger Sponge" image="./images/mengerSponge.jpg" description="Use your mouse to interact with a 3D rotating Menger Sponge."/>
          <Project link="https://www.openprocessing.org/sketch/750260" title="Fireworks" image="./images/fireworks.jpg" description="Click to launch a firework. Press the space bar to start a show."/>
        </div>
      </div>
    );
  }
}

export default Home;
