import React, {Component} from 'react';

const Resume = function(){
  //<p>GuSH Grant, to create the VoiceBow, an input device for a speech therapy game for the Deaf</p>
    return(
      <div>
        <h2>EDUCATION:</h2>
        <p><b>Carnegie Mellon University: Entertainment Technology Center</b><br/>Pittsburgh, PA, May 2006 Master of Entertainment Technology (A program that combines Computer Science with Art)</p>
        <p><b>College of William and Mary</b><br/>Williamsburg, VA, December 2002 Bachelor of Arts in European Studies, Minor in Economics</p>
        <h2>EXPERIENCE:</h2>

        <h3>Software Engineer/ Gaming and Robotics Instructor<br/>
        The Coder School Fremont, CA November 2017 - Present</h3>
        <p>Creating 2d, 3d, and AR games in Unity for desktop and mobile. Using JavaScript and Python to build online games and
        digital art projects. Developing a robotics curriculum including autonomous RC cars and drawing robots. Teaching code
        camps for Python, JavaScript, the Raspberry Pi, and C#.</p>

        <h3>Room Escape Puzzle Engineer, Scrap: Real Escape Game (Freelance)<br/>San Francisco, CA November 2018 - June 2019</h3>
        <p>Creating mobile applications in Unity to connect over a network with computers to enable game masters to control audio and visual playback within escape rooms. Developed a Unity AR image target tracking puzzle minigame to entertain guests while they wait for rooms to start.</p>
        <h3>Technology Professor/Advocate, Maker Club Founder<br/>Shizuoka Prefectural Board of Education, Fuji, Shizuoka, Japan August 2015 - August 2017</h3>
        <p>Taught students how to create and program games using Unity. Created workshops to introduce technology topics to students and teachers using virtual reality, 3D printing/scanning, laser engraving, and video game/app creation. Created a maker space for local area schools and built a 3D printer, laser engraver, 3D scanner, etc. to furnish it. Used technology to build devices to assist disabled students, most notably an eye movement tracker which was used for keyboard input by paralyzed students.</p>
        <h3>Software Engineer, International Gaming Technologies<br/>San Francisco, CA February 2012 - August 2015</h3>
        <p>Member of the Game Development Kit team creating a backbone system for connecting backend game logic to front-end Flash gambling games while keeping them secure in compliance with regional laws. Created a game agnostic tool in Actionscript and again in Javascript/HTML5 for forcing outcomes for testing and demonstrating game features. Supported a third party company based in Russia to create new game titles and integrate them with our current system.</p>
        <h3>Software Engineer, Crowdstar Inc.<br/>Burlingame, CA June 2011 — January 2012</h3>
        <p>Created and modified code for a Facebook fashion game. Created content editing software using Flex to support the creation and modification of YAML files. Worked with artists on implementing UI elements for the game.</p>
        <h3>Software Engineer, Playfirst Inc.<br/>San Francisco, CA November 2010-May 2011</h3>
        <p>Programmed and maintained code for the Facebook game Diner Dash. Wrote JSFL Flash extensions to optimize the art pipeline for integration of assets. Implemented and tested 3rd party features for monetization into the live game.</p>
        <h3>Flash Developer, Allen Interactions<br/>San Francisco, CA November 2008-November 2010</h3>
        <p>Programmed and designed interactive eLearning applications, externalized all text and data from interaction to be controlled by XML to allow for easy modification and localization of interactions, rewrote several interactions in AS3 and optimized code in the process.</p>
        <h3>Flash Game Developer, Quotient Inc./National Institute of Health<br/>Bethesda, MD August 2007-Present</h3>
        <p>Designed and programmed Flash games that teach aspects of world health to children. Created art and animations for use in the games. Researched new programming and animation techniques and created rapid prototypes and toys.</p>
        <h3>Programmer/Animator, Dharma Publishing<br/>Cazadero, CA April 2007-August 2007</h3>
        <p>Programmed PHP code to implement a new website design for www.dharmapublishing.com. Created a custom search engine for the site. Programmed AJAX keyword suggestion feature for the search engine. Programmed interactive map using Google Map API. Wrote javascript functions to rotate weekly and monthly products featured on the site. Repaired and enhanced code from previous programmer to add new site features. Animated a children's story based on one of the Jataka Tales from ancient India.</p>
        <h3>Technical Artist/Animator/Programmer, Carnegie Mellon University: CarnegieCadets<br/>Pittsburgh, PA Summer 2005, May 2006-March 2007</h3>
        <p>Programmed and designed Flash games to teach fundamentals of internet safety to 11-15 year-olds. Developed techniques for creating animated special effects. Researched several animation software packages, created an animation pipeline, and trained animators in the best methods for using new software programs. Designed and animated characters and objects for the games. Programmed mini-games for slow internet connections (files less than 2 MB). Provided the voice of the game’s character Carlo.</p>
        <h2>SKILLS:</h2>
        <p>Unity, Virtual Reality, Adobe Photoshop, Adobe Illustrator, Maya, Zbrush, Flash, AJAX, Anime Studio Pro, ToonBoom Studio, Adobe AfterEffects, ProTools, Adobe Audition, Adobe Premiere, Photography, Chinese Brush Painting, Voice acting, Tuvan throat singing</p>
        <h2>LANGUAGES:</h2>
        <p>C#, Typescript, Javascript, HTML, CSS, PHP, Java, Swift, Actionscript</p>
        <p>Fluent in German, very proficient in Japanese, very proficient in Russian, proficient in Mandarin Chinese</p>
        <h2>HONORS/AWARDS:</h2>
        <p>Received 2015 & 2016 JET Alumni Association grants to establish a Maker's Club in Fuji City</p>
        <p>Speaker GDC China 2014: Building Tools for Empowering Creativity and Improving Efficiency</p>
        <p>2006 2 Telly Awards, Don on the Farm, 2D Animation</p>
      </div>
    );
}

export default Resume;
