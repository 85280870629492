import React, {Component} from 'react';
import GameEntry from './GameEntry';
import "./styles/games.css";

class Games extends Component{
  render(){
    return(
      <div>
        <h1>Games</h1>
        <ul>
          <a href="#slots">Slot machine games</a>
          <a href="#social">Social Network games</a>
          <a href="#education">Educational games</a>
          <a href="#compSci">Games for teaching</a>
          <a href="#fun">Games made for fun</a>
        </ul>
        <h2 id="slots">Online Slot Games</h2>

        <GameEntry title="Pharaoh's Fortune">
          Feel like a king with this classic iconic theme which features five reels, 15 paylines, and is filled with entertaining animation sequences and great music.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/b1Poffi5hGI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a target ="_blank" href="https://www.penny-slot-machines.com/games/pharaohs-fortune-slots.html">Play Pharaoh's Fortune online here.</a>
        </GameEntry>

        <GameEntry title="Black Widow">
          Black Widow online video slot game is a 5-reel slot machine with a fixed 40 lines. This game is entertaining and offers a ton of unique features not found in many other games including the web capture feature and the super stacks feature.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/CNG_8zDoRVk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </GameEntry>

        <GameEntry title="Double DaVinci Diamonds">
          Inspired by the original DaVinci Diamonds® Video Slots, one of the most successful themes ever released, this new addition to the successful Tumbling Reels® game family is sure to be a player favorite.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/WaWu47T7zMM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </GameEntry>

        <GameEntry title="Golden Goddess">
          Win the favor of the Goddess on every spin!
          Selective Stacking locks a major symbol on each spin for exciting base game play and larger payouts
          Players choose their Super Stacks™ in the bonus to create giant wins in the feature
          Features a Free Games bonus with Stacked Wilds® and eye-catching graphics
          Configurable line options all on one game program support multiple denomination segments
          Increased stacks in the Free Games produce jaw-dropping wins.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ThuJJ-0243M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a target ="_blank" href="https://www.penny-slot-machines.com/games/golden-goddess.html">Play Golden Goddess online here.</a>
        </GameEntry>

        <GameEntry title="Cats">
          Calling all animal lovers! Cats free online slot will not leave you indifferent if you wish to play casino games online and enjoy the animal theme. Indeed, this fabulous 5-reel, 30-line game is one of the main attention-grabber’s on the net.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/He-lafr7TKQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a target ="_blank" href="https://www.penny-slot-machines.com/games/cats.html">Play Cats online here.</a>
        </GameEntry>

        <GameEntry title="Cleopatra">
          Take a Trip Down the Nile and Unravel the Mysteries of Ancient Egypt.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/sLipA7bxqAo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a target ="_blank" href="https://www.penny-slot-machines.com/games/cleopatra-slots.html">Play Cleopatra online here.</a>
        </GameEntry>

        <GameEntry title="Wolf Run">
          Wolf Run online slots, one of the most popular slots from IGT, is a 5-reel, 40-payline game that features Stacked Wilds, amazing big wins and a super-rich, retriggering Free Spins bonus. On every reel there are stacks of four or more wild symbols.
          <iframe width="560" height="315" src="https://www.youtube.com/embed/L0_FKyWhE2E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a target ="_blank" href="https://www.penny-slot-machines.com/games/wolf-run-slots.html">Play Wolf Run online here.</a>
        </GameEntry>

        <h2 id="compSci">Games for teaching</h2>
        <p>I've made a variety of games for teaching coding concepts.</p>

        <GameEntry title="Blob.io">
          <p>This game started as a clone of the popular online game agar.io. I designed this game to demonstrate classes and effectively using inheritance to easily add more complex game behavior without having to write a lot of extra code. It has become a student favorite.</p>
          <img src="./images/blobio.png" alt="screenshot from blob.io"/>
        </GameEntry>

        <GameEntry title="Class inheritance and Creating a sprite class">
        This simple Java Script game was created to demonstrate using classes and inheritance. The sprite class has been used by my students as a basis for writing their own classes and as a base class to extend to create sprites with more complex behavior.
        Game with dog catching bats.
        </GameEntry>

        <h2 id="social">Social Network Games</h2>

        <GameEntry title="Diner Dash: Facebook">
          <p>The premise of Diner Dash on Facebook follows Flo and her continuous battle against the evil Mr. Big. Mr. Big has polluted the DinerTown park forcing most restaurants to close. Players must help Flo take on Mr. Big and use their tips earned in the restaurant to fix the park, allowing other restaurants to be unlocked.</p>
          <img src="./images/dinerDash1.jpg" alt="screenshot of a clean and decorated park in Diner Dash"/>
          <img src="./images/dinerDash2.jpg" alt="screenshot of a diner in Diner Dash"/>
          <img src="./images/dinerDash3.jpg" alt="screenshot of the world map of Diner Dash"/>
          <img src="./images/dinerDash4.jpg" alt="screenshot of the trash in the park in Diner Dash"/>
        </GameEntry>

        <h2 id="education">Educational Games</h2>

        <GameEntry title="MySecureCyberspace Game: Carnegie Cadets">
          <p>The goal of the MySecureCyberspace Game is to educate children ages 8-11 about cybersecurity and instill in them good cybercitizen habits so that being safe and secure online becomes as second nature as brushing your teeth or looking both ways before crossing the street. The player is a trainee in the Carnegie Cyber Academy who must enter CyberSpace, the city that exists inside the Internet to learn about cybersecurity. The player completes several missions, mini-games designed to teach major cybersecurity principles such as how to use judgement when sorting and opening email and what is safe information to share with others online.</p>
          {/*<a href="http://www.carnegiecyberacademy.com">Visit the game page.</a>*/}
          <img src="./images/cc1.jpg" alt="screenshot ofCommander Omni's office"/>
          <img src="./images/cc2.jpg" alt="screenshot of an email sorting game"/>
          <img src="./images/cc3.jpg" alt="screenshot of Betty at the entrance to the chat room"/>
          <img src="./images/cc4.jpg" alt="screenshot of Cadet Carlo's bedroom"/>
          <img src="./images/cc5.jpg" alt="screenshot of the Academy Store"/>
          <img src="./images/cc6.jpg" alt="screenshot of the Academy Cafeteria"/>
          <img src="./images/cc7.jpg" alt="screenshot Trey at the email sorting center"/>
        </GameEntry>

        <GameEntry title="Find My Instruments!">
          <p>A hidden object game which teaches children about the sorts of tools they might find a doctor using. The game takes place in a patient waiting room where a doctor asks the player to look for his instruments. As each tool is collected more information about that tool is revealed. The idea behind this game is that if the children know what a doctor's instruments are for, they will not feel frightened when they visit a real doctor's office.</p>
          <img src="./images/instruments.jpg" alt="screenshot from Find my Instruments that shows a doctor's waiting room"/>
        </GameEntry>
        <GameEntry title="Against All Odds Exhibit: Global Health Everywhere Games">
        <p>These games were created to compliment the Against All Odds exhibit for global health at the National Library of Medicine. Each game focuses on geography in the context of world health. These games are accessible to the mobility and vision impaired.</p>
        <figure>
          <img src="./images/NIHgame1.png" alt="screenshot of a word scramble game"/>
          <figcaption>Unscramble the name.</figcaption>
        </figure>
        <figure>
          <img src="./images/NIHgame2.jpg" alt="screenshot of a world map showing health information"/>
          <figcaption>Global Health Everywhere games.</figcaption>
        </figure>
        <figure>
          <img src="./images/NIHgame3.png" alt="screenshot of a multiple choice global health quiz"/>
          <figcaption>Test your knowledge game.</figcaption>
        </figure>
        <figure>
          <img src="./images/NIHgame4.png" alt="screenshot of an image matching game depicting world health leaders"/>
          <figcaption>Who's who in global health game.</figcaption>
        </figure>
        <a href="https://www.nlm.nih.gov/exhibition/againsttheodds/online_activities/index.html">Play the games here.</a>
        </GameEntry>
        <h2 id="fun">Games made for fun</h2>
        <GameEntry title="Metamorphosis">
          <p>Metamorphosis is an object avoidance game inspired by Kafka's work of the same name. This demo demonstrates the game play and showcases a new type of collision detection based on analyzing bitmap data. <a href="http://animateinexile.blogspot.com/2008/08/bitmap-collision-detection.html">Read more about Bitmap Collision Detection at my old blog.</a></p>
          <img src="./images/metamorphosis.gif" alt="screenshot of an object avoidance game"/>
          {/*<a href="">Play the game here.</a>*/}
        </GameEntry>
      </div>
    );
  }
}

export default Games;
